import React, { useRef, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SpeakerNotes from "@mui/icons-material/SpeakerNotes";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Notes = (props) => {
  const { isDataLoaded, assigneeName, activeTaskId, activeTaskNotes } = props;

  const notesRef = useRef(null);
  const iconMin = useRef(null);
  const iconMax = useRef(null);
  const notesSend = useRef(null);

  useEffect(() => {
    let scroll_to_bottom = document.getElementById("notes-app");
    scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
  }, [isDataLoaded]);

  function onEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      addNote();
    }
  }

  function addNote() {
    console.log("assigneeName: " + assigneeName);
    console.log("activeTaskId: " + activeTaskId);

    let note = document.getElementById("note-input").value;

    if (note === "") {
      return;
    }

    let note_wrapper = document.createElement("div");
    let note_bubble = document.createElement("div");
    let note_author = document.createElement("div");

    note_wrapper.classList.add("note");
    note_bubble.classList.add("note-bubble");
    note_author.classList.add("note-author");

    note_bubble.innerHTML = note;
    note_author.innerHTML = "@" + assigneeName;

    document.getElementById("notes-app").appendChild(note_wrapper);
    let last = Array.from(document.querySelectorAll(".note")).pop();
    last.appendChild(note_bubble);
    last.appendChild(note_author);
    document.getElementById("note-input").value = "";

    pushNote(note, assigneeName);
  }

  function pushNote(note, note_ath) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "https://fusion-prod-282716.uk.r.appspot.com/notes");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onload = () => console.log(xhr.responseText);

    let data = {
      notes: [
        {
          details: note,
          name: assigneeName,
          taskId: activeTaskId,
        },
      ],
    };

    xhr.send(JSON.stringify(data));
    let scroll_to_bottom = document.getElementById("notes-app");
    scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
  }

  return (
    <div className="col-1-3 flex-direction-column flex-start notes-width" id="task-notes" ref={notesRef}>
      <div className="min-max-wrapper">
        <ExpandMoreIcon
          className="minimize-icon"
          ref={iconMin}
          onClick={() => {
            notesRef.current.classList.add("minimized");
            notesSend.current.classList.add("minimized");
            iconMin.current.classList.contains("disabled")
              ? iconMin.current.classList.remove("disabled")
              : iconMin.current.classList.add("disabled");
            iconMax.current.classList.remove("disabled");
          }}
        />
        <ExpandLessIcon
          className="maximize-icon disabled"
          ref={iconMax}
          onClick={() => {
            notesRef.current.classList.remove("minimized");
            notesSend.current.classList.remove("minimized");
            iconMax.current.classList.contains("disabled")
              ? iconMax.current.classList.remove("disabled")
              : iconMax.current.classList.add("disabled");
            iconMin.current.classList.remove("disabled");
          }}
        />
      </div>
      <h3>
        <span className="notes-icon">
          <SpeakerNotes />
        </span>
        Task Notes
      </h3>
      <div id="notes-app" className="col-1-1 flex-direction-column flex-start flex-justify-start">
        {activeTaskNotes.map((note, index) => (
          <div className="note" key={index}>
            <div className="note-bubble">
              <div>{note.description}</div>
            </div>
            <div className="note-author">
              <div>@{note.name}</div>
            </div>
          </div>
        ))}
      </div>
      <div id="note-send-app" ref={notesSend}>
        <input
          type="text"
          className="note-send-msg"
          placeholder="Type here to send a note"
          id="note-input"
          onKeyDown={onEnter}
        />
        <div className="note-send-button" id="sendNote" onClick={addNote}>
          <span className="material-icons md-36">
            <ArrowForwardIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Notes;
