export const Styles = {
  MultiSelect_OtherOption: {
    question: {
      color: "rgba(0, 0, 0, 0.6)",
      fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.4375em",
      letterSpacing: "0.00938em",
      padding: 0,
      position: "relative",
    },
    input: {
      width: "18px",
      height: "18px",
      marginRight: "12px",
      marginTop: "13px",
    },
    label: {
      marginTop: "7px",
    },
    textInput: {
      fontSize: "16px",
      marginLeft: "15px",
      width: "350px",
      height: "18px",
      borderRadius: "4px",
    },
  },
};
