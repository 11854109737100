import React from "react";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import "./styles/TableView.css";

const TableView = (props) => {
  const { isToggleActive, handleToggleClick } = props;

  return (
    <div className="tableView">
      <span>Preview Mode</span>
      <ToggleOffIcon
        className={isToggleActive ? "toggleOffIcon active" : "toggleOffIcon"}
        onClick={handleToggleClick}
      />
    </div>
  );
};

export default TableView;
