import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import "./styles/FormSplitter.css";

const baseApi = process.env.REACT_APP_API_ENDPOINT;

const FormSplitter = (props) => {
  const { schemas, insightSchema, isToggleActive, isDataLoaded, mainFlowId, version } = props;

  const [tabs, setTabs] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [formMap, setFormMap] = useState({});
  const [required_ORIG, setRequired_ORIG] = useState([]);

  const tabStatus = useRef({});

  useEffect(() => {}, [rerender]);

  useEffect(() => {
    let rawTabs = [];
    let formIds = [];
    let tabTree = {};

    for (const [key, value] of Object.entries(schemas.formSchemaUI)) {
      rawTabs.push(value.nav);
      formIds.push(key);
      tabTree[value.nav] = [];
      setFormMap((prevState) => {
        return { ...prevState, [value.nav]: [] };
      });
    }

    for (const [key, value] of Object.entries(schemas.formSchemaUI)) {
      tabTree[value.nav].push(key);
      setFormMap(tabTree);
    }

    const uniqueTabs = removeDuplicates(rawTabs);
    setTabs(uniqueTabs);

    _.forEach(uniqueTabs, (tab) => {
      const tabEnabled = insightSchema.sections.find((a) => a.title === tab)?.isEnabled;
      tabStatus.current[tab] = tabEnabled;
    });

    setRequired_ORIG(_.cloneDeep(schemas.formSchema.required));

    // fire up first tab
    setTimeout(() => {
      const clickEl = document.getElementById(`${uniqueTabs[0]}`);
      clickEl.click();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  // sticky nav
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSticky = () => {
    if (isToggleActive) {
      return;
    }

    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;

    const el = document.querySelector(".formSplitter");
    if (scrollTop > 540) {
      el.classList.remove("is-not-sticky");
      el.classList.add("is-sticky");
    } else {
      el.classList.remove("is-sticky");
      el.classList.add("is-not-sticky");
    }
  };

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const hideShowElements = (tab) => {
    for (const [key, value] of Object.entries(formMap)) {
      value.forEach((id) => {
        const elString = `[id^="root_${id}"]`;
        const formElements = document.querySelectorAll(elString);

        if (key === tab && tabStatus.current[tab]) {
          formElements.forEach((el) => {
            el.parentElement.parentElement.parentElement.classList.remove("hiddenElement");
            el.parentElement.parentElement.parentElement.classList.add("activeElement");
          });
        } else {
          formElements.forEach((el) => {
            el.parentElement.parentElement.parentElement.classList.remove("activeElement");
            el.parentElement.parentElement.parentElement.classList.add("hiddenElement");
          });
        }
      });
    }
  };

  function handleClick(e) {
    setSelectedTab(e.target.id);

    const tabEnabled = insightSchema.sections.find((a) => a.title === e.target.id)?.isEnabled;
    if (!tabEnabled) {
      const categorySection = schemas.insightSchemas?.sections.filter((a) => a.title === selectedTab)[0];
      const subCategorySections = _.flatten(categorySection.sections.map((a) => a.elements));
      const questions = subCategorySections.map((a) => a.id);
      const required = schemas.formSchema?.required;

      // TURNED OFF
      _.remove(required, (item) => questions.includes(item));
    }

    document.querySelectorAll(".lightUp").forEach((el) => {
      el.classList.remove("lightUp");
    });

    if (!e.target.className.includes("disabled")) {
      e.currentTarget.classList.add("lightUp");
    }

    let tab = e.target.innerHTML;
    hideShowElements(tab);
  }

  const tabStatusChange = () => {
    tabStatus.current[selectedTab] = !tabStatus.current[selectedTab];

    const categorySection = schemas.insightSchemas?.sections.filter((a) => a.title === selectedTab)[0];
    const subCategorySections = _.flatten(categorySection.sections.map((a) => a.elements));
    const questions = subCategorySections.map((a) => a.id);
    const required = schemas.formSchema?.required;

    fetch(`${baseApi}/flow/form/category_toggle`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
      body: JSON.stringify({
        flowId: mainFlowId,
        versionId: version.id,
        category: categorySection.title,
        isEnabled: tabStatus.current[selectedTab],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (tabStatus.current[selectedTab]) {
          // TURNED ON
          _.forEach(questions, (item) => {
            if (required_ORIG.includes(item)) {
              required.push(item);
            }
          });
        } else {
          // TURNED OFF
          _.remove(required, (item) => questions.includes(item));
        }
        hideShowElements(selectedTab);
        setRerender(!rerender);
      })
      .catch((error) => {
        console.log("API ERROR: ", error.message);
      });
  };

  return (
    <>
      <div className="col-1-1">
        <ul className="formSplitter">
          {tabs.map((tab, index) => {
            return (
              <li id={tab} className={tabStatus.current[tab] ? null : "disabled"} key={index} onClick={handleClick}>
                {tab}
              </li>
            );
          })}
        </ul>
      </div>
      {!_.isEmpty(tabStatus.current) && selectedTab.length && (
        <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "100px" }}>
          <span className="sectionStatusQuestion">Do you have this capability?</span>
          <RadioGroup
            row
            name="controlled-radio-buttons-group"
            value={tabStatus.current[selectedTab] ? "yes" : "no"}
            onChange={tabStatusChange}
          >
            <FormControlLabel value="no" control={<Radio />} label="No" />
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          </RadioGroup>
        </div>
      )}
    </>
  );
};

export default FormSplitter;
