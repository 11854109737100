import React from "react";
import "./styles/AccessDenied.css";

const AccessDenied = () => {
  return (
    <div className="denied">
      <h3>Ooops, you don't seem to have access</h3>
    </div>
  );
};

export default AccessDenied;
