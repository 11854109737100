import React from 'react';
import { Styles } from './style';

const MultiSelect_OtherOptionStyles = Styles.MultiSelect_OtherOption;

const MultiSelect_OtherOption = (props) => {

  const { id, label, options, value, required, readonly, autofocus, onBlur, onFocus, onChange, rawErrors } = props;
  const { enumOptions } = options;

  const cssClass = `${rawErrors?.length ? " Mui-error" : ""}`;
  
  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const hasOtherOption = selectedValues?.includes("Other");

    if (hasOtherOption) {
      onChange({ value: selectedValues, Other: value.Other || "" });
    } else {
      onChange({ value: selectedValues });
    }
  };

  return (
    <>
      <div style={MultiSelect_OtherOptionStyles.question}>
        <span aria-hidden="true" className={cssClass}>{label}</span>
        {required && <span aria-hidden="true" className={cssClass}> *</span>}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {enumOptions.map((option, index) => {
          if(index < enumOptions.length - 1) {
            return (
            <div key={index}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={MultiSelect_OtherOptionStyles.input}
                  type="checkbox"
                  id={`${id}-${index}`}
                  value={option.value}
                  checked={(value?.value?.includes(option.value)) || false}
                  required={required}
                  readOnly={readonly}
                  autoFocus={autofocus}
                  onBlur={((event) => onBlur(id, event.target.value))}
                  onFocus={((event) => onFocus(id, event.target.value))}
                  onChange={(event) => handleChange([...event.target.form.elements].filter((el) => el.id.includes(id) && el.checked))}
                />
                <label style={MultiSelect_OtherOptionStyles.label} htmlFor={`${id}-${index}`}>{option.label}</label>
              </div>
            </div>)
          } else {
            return (
              <div style={{ display:"flex", alignItems: 'center', marginBottom: '20px' }} key={index}>
                <input
                  style={MultiSelect_OtherOptionStyles.input}
                  type="checkbox"
                  id={`${id}-${index}`}
                  value={option.value}
                  checked={(value?.value?.includes(option.value)) || false}
                  required={required}
                  readOnly={readonly}
                  autoFocus={autofocus}
                  onBlur={((event) => onBlur(id, event.target.value))}
                  onFocus={((event) => onFocus(id, event.target.value))}
                  onChange={(event) => handleChange([...event.target.form.elements].filter((el) => el.id.includes(id) && el.checked))}
                />
                <label style={MultiSelect_OtherOptionStyles.label} htmlFor={`${id}-${index}`}>{option.label}</label>
                {value?.value?.includes("Other") && (
                  <div style={MultiSelect_OtherOptionStyles.label}>
                    <input
                      style={MultiSelect_OtherOptionStyles.textInput}
                      className='other'
                      type="text"
                      id={`${id}-Other`}
                      value={value.Other || ""}
                      onChange={(event) => onChange({ ...value, Other: event.target.value })}
                    />
                  </div>
                )}
              </div>
            )
          }
        })}
      </div>
    </>
  );
};

export default MultiSelect_OtherOption;