import React from "react";
import "./styles/AutoSave.css";

const AutoSave = () => {
  return (
    <div className="autoSave">
      <p>Auto Saved!</p>
    </div>
  );
};

export default AutoSave;
