import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Hero from "../components/Hero";
import FormJSONRef from "../components/FormJSON";
import Notes from "../components/Notes";
import TaskStatus from "../components/TaskStatus";
import "../components/styles/Notes.css";

const Page1 = (props) => {
  const {
    assigneeName,
    pageTitle,
    activeTaskName,
    nodesTotal,
    nodesDone,
    setNodesDone,
    percentDone,
    setPercentDone,
    activeTaskId,
    activeFlowId,
    activePath,
    partner,
    schemas,
    formSchema,
    setFormSchema,
    formSchemaUI,
    appData,
    setAppData,
    insightSchema,
    isDataLoaded,
    getFormUrl,
    activeTaskNotes,
    mainFlowId,
    version,
    isSubmitActive,
    setIsSubmitActive,
    lastSubmitted,
    partnerName
  } = props;

  const title = useParams().title;

  const rjsfRef = useRef(null);

  const handleSubmit = () => {
    if (rjsfRef.current) {
      rjsfRef.current.submit();
    }
  };

  const incomplete = useRef(false);

  return (
    <div className="col-1-1 flex-direction-column">
      <Header title={pageTitle} />
      <TaskStatus activeTaskName={activeTaskName} />
      <Hero
        title={title ? title : partnerName ? `Hi ${partnerName}` : `Hi there ...`}
        activeTaskId={activeTaskId}
        activeFlowId={activeFlowId}
        activePath={activePath}
        bgColor="#5e00b5"
        partner={partner}
        mainFlowId={mainFlowId}
        version={version}
        incomplete={incomplete}
        handleSubmit={handleSubmit}
        isSubmitActive={isSubmitActive}
        setIsSubmitActive={setIsSubmitActive}
      />
      <FormJSONRef
        isDataLoaded={isDataLoaded}
        schemas={schemas}
        formSchema={formSchema}
        setFormSchema={setFormSchema}
        formSchemaUI={formSchemaUI}
        insightSchema={insightSchema}
        appData={appData}
        setAppData={setAppData}
        nodesDone={nodesDone}
        setNodesDone={setNodesDone}
        nodesTotal={nodesTotal}
        percentDone={percentDone}
        setPercentDone={setPercentDone}
        getFormUrl={getFormUrl}
        mainFlowId={mainFlowId}
        version={version}
        ref={rjsfRef}
        incomplete={incomplete}
        onSubmit={handleSubmit}
        setIsSubmitActive={setIsSubmitActive}
        lastSubmitted={lastSubmitted}
        partnerName={partnerName}
      />
      <Notes
        isDataLoaded={isDataLoaded}
        assigneeName={assigneeName}
        activeTaskId={activeTaskId}
        activeTaskNotes={activeTaskNotes}
      />
    </div>
  );
};

export default Page1;
