import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page1 from "./pages/Page1";
import Loader from "./components/Loader";
import AccessDenied from "./components/AccessDenied";
import "./styles/grid.css";
import "./styles/typography.css";
import "./styles/App.css";

import LocalData from "./data/FileUploadPlayground.json";

function App() {
  const [isTaskComplete, setIsTaskComplete] = useState(false);
  const [hasAccess, setHasAccess] = useState("Pending");
  const [assigneeName, setAssigneeName] = useState("");
  const [schemas, setSchemas] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [formSchemaUI, setFormSchemaUI] = useState({});
  const [appData, setAppData] = useState({});
  const [insightSchema, setInsightSchema] = useState({});
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [activeTaskName, setActiveTaskName] = useState("");
  const [activeTaskNotes, setActiveTaskNotes] = useState([]);
  const [activeTaskId, setActiveTaskId] = useState("");
  const [activeFlowId, setActiveFlowId] = useState("");
  const [activePath, setActivePath] = useState("");
  const [nodesTotal, setNodesTotal] = useState(0);
  const [nodesDone, setNodesDone] = useState(0);
  const [percentDone, setPercentDone] = useState(0);
  const [partner, setPartner] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [flowData, setFlowData] = useState([]);
  const [mainFlowId, setMainFlowId] = useState("");
  const [version, setVersion] = useState(-1);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [lastSubmitted, setLastSubmitted] = useState("01/01/2024");
  const [partnerName, setPartnerName] = useState("");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const baseApi = process.env.REACT_APP_API_ENDPOINT;

  let token = urlParams.get("token");

  let local = urlParams.get("local");
  const getFormUrl = `${baseApi}/flow/form?token=${token}`;

  function handleClick() {
    setIsTaskComplete((prevState) => {
      return prevState ? false : true;
    });
  }

  // get main flow id
  useEffect(() => {
    setMainFlowId(flowData.flowId);
    setAssigneeName(flowData.assigneeName);
    setPartner(flowData.partner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded, flowData]);

  // get flow data
  useEffect(() => {
    fetch(getFormUrl)
      .then((response) => response.json())
      .then((data) => {
        if (local === "true") {
          data = LocalData;
        } else {
          data = data.data;
          if (data.flow_data.version.hasChanged === 1) {
            setIsSubmitActive(true);
          }
        }

        setSchemas(data.schemas);
        setVersion(data.flow_data.version);
        setDataLoaded(true);
        setFormSchema(data.schemas.formSchema);
        setFormSchemaUI(data.schemas.formSchemaUI);
        setInsightSchema(data.schemas.insightSchemas);
        setAppData(data.schemas.appData[0].currPartnerData);
        setPageTitle(data.schemas.insightSchemas.title);
        setFlowData(data.flow_data);
        setPartnerName(data.flow_data.partnerName);
        setLastSubmitted(data.flow_data.version.submittedDate);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskComplete]);

  // got access?
  useEffect(() => {
    fetch(`${baseApi}/token/${token}`)
      .then((response) => response)
      .then((data) => {
        setHasAccess(data.status);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (hasAccess === 200) {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Page1
                assigneeName={assigneeName}
                schemas={schemas}
                formSchema={formSchema}
                formSchemaUI={formSchemaUI}
                appData={appData}
                setAppData={setAppData}
                isDataLoaded={isDataLoaded}
                insightSchema={insightSchema}
                activeTaskName={activeTaskName}
                activeTaskNotes={activeTaskNotes}
                activeTaskId={activeTaskId}
                activeFlowId={activeFlowId}
                activePath={activePath}
                getFormUrl={getFormUrl}
                nodesTotal={nodesTotal}
                nodesDone={nodesDone}
                setNodesDone={setNodesDone}
                percentDone={percentDone}
                setPercentDone={setPercentDone}
                partner={partner}
                pageTitle={pageTitle}
                mainFlowId={mainFlowId}
                version={version}
                setIsSubmitActive={setIsSubmitActive}
                isSubmitActive={isSubmitActive}
                lastSubmitted={lastSubmitted}
                partnerName={partnerName}
              />
            }
          />
          <Route path="/home/:title" element={<Page1 />} />
        </Routes>
      </BrowserRouter>
    );
  } else if (hasAccess === "Pending") {
    return <Loader token={token} />;
  } else {
    return <AccessDenied />;
  }
}

export default App;
