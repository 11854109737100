export const Styles = {
  QUESTIONNAIRE: {
    question: {
      fontSize: "16px",
      color: "#666666",
      margin: "36px 0 -24px 0",
    },
    uploadedFile: {
      fontSize: "16px",
      color: "#666666",
      margin: "44px 0 -22px 0",
      width: "225px",
    },
    fileName: {
      fontSize: "16px",
      color: "#0014cc",
      margin: "44px 0 -22px 0",
    },
    subCategory: {
      margintop: "22px",
      fontSize: "36px",
      fontWeight: "500",
    },
    submitButton: {
      minWidth: "224px !important",
      backgroundColor: "#0014cc",
    },
  },
};
