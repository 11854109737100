import React, { useEffect } from "react";
import "./styles/FormTable.css";

const FormTable = (props) => {
  const { isDataLoaded, insightSchema, appData } = props;

  useEffect(() => {
    if (isDataLoaded) {
      buildTable(insightSchema);
      let insightContainer = document.querySelector("#container");
      insightContainer.innerHTML = insightHTML;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  //Name of the div where we inject the table content
  const insightTableSelector = "insight_table_div";
  //Holds HTML created from insightData.schemas.insightSchema and insightData.schemas.appData
  let insightHTML = ``;
  //Get answer looks at the the insightData.schemas.appData for the respective answers
  const getAnswer = (id) => {
    let answer = "&nbsp;";
    if (id in appData) {
      answer = appData[id];
      if (answer === true) {
        answer = "Yes";
      }
      if (answer === false) {
        answer = "No";
      }
      if (typeof answer === "string" && answer.startsWith("platform_data_upload")) {
        answer = `file: ${answer.split("/")[2]}`;
      }
      if (Array.isArray(answer)) {
        let arrayToLoop = answer;
        let tmp = "";
        arrayToLoop.forEach((element) => {
          tmp = tmp + `<div class="chip">${element}</div>`;
        });
        answer = tmp;
      }
      if (typeof answer === "object") {
        if (Object.keys(answer)[0] === "SingleSelect") {
          if (answer["SingleSelect"] === "") {
            answer = "";
          }
        }
      }
    }
    return answer;
  };
  //Build table constructs the table from the schema model
  const buildTable = (schema, sectionLevel = 0) => {
    //Title provide header values
    if (schema.title) {
      let headingLevel = sectionLevel + 1;

      if (sectionLevel == 0) {
        insightHTML = `<h1>${schema.title}</h1><table class="table"><tbody id="insightTable">`;
      } else {
        let sectionClass = "section-" + headingLevel;
        insightHTML =
          insightHTML +
          `<tr class="table-row ${sectionClass}"><td colspan="2" class="section "><h${headingLevel}>${schema.title}</h${headingLevel}></td></tr>`;
      }
    }

    //Elements are responses.  Show them before looking for subsections
    if (schema.elements) {
      if (schema.elements.length > 0) {
        //Lowest level, show questions and answers
        schema.elements.forEach((element) => {
          let answers = getAnswer(element.id);
          if (typeof answers === "object") {
            if (Array.isArray(answers.value)) {
              const arrayToLoop = answers.value;
              let tmp = "";
              arrayToLoop.forEach((element) => {
                tmp = tmp + `<div class="chip">${element}</div>`;
              });
              if (answers.Other) {
                answers = tmp + `"${answers.Other}"`;
              }
            }
          }
          insightHTML = insightHTML + `<tr class="table-row"><td>${element.title} </td><td><b>${answers}</b></td></tr>`;
        });
      }
    }

    //Sections hold lower levels
    if (schema.sections && schema.sections.length > 0) {
      sectionLevel += 1;
      schema.sections.forEach((section) => {
        buildTable(section, sectionLevel);
      });
    } else if (schema.elements.length === 0) {
      //End HTML build
      insightHTML = insightHTML + `</tbody></table>`;
    }
  };

  return (
    <div className="formTable col-1-1">
      <div id="container"></div>
    </div>
  );
};

export default FormTable;
