import _ from "lodash";
import React, { useState, useEffect, useCallback, useRef, forwardRef } from "react";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/mui";
import { IconButton, Snackbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelect_OtherOption from "./MultiSelect_OtherOption";
import FormSplitter from "./FormSplitter";
import AutoSave from "./AutoSave";
import FormTable from "./FormTable";
import TableView from "./TableView";
import { Styles } from "./styles";
import "./styles/FormJSON.css";

const FormJSON = (props, ref) => {
  const {
    isDataLoaded,
    schemas,
    formSchema,
    formSchemaUI,
    insightSchema,
    appData,
    setAppData,
    nodesDone,
    setNodesDone,
    nodesTotal,
    setPercentDone,
    getFormUrl,
    mainFlowId,
    version,
    incomplete,
    onSubmit,
    setIsSubmitActive,
    lastSubmitted,
  } = props;

  const [isToggleActive, setIsToggleActive] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const versionRef = useRef();
  versionRef.current = version;
  const mainFlowIdRef = useRef();
  mainFlowIdRef.current = mainFlowId;

  const validationErrors = useRef();

  const snackBarMessages = {
    fileUploading: "Uploading file, hang tight...",
    fileUploadSuccess: "File uploaded successfully!",
    fileUploadFail: "File upload failed! Please re-choose your file and try again.",
  };

  function TitleFieldTemplate(props) {
    const { id, title, uiSchema, required } = props;

    // APPLIES TO FILE UPLOADS AND SINGLE SELECTS
    let Id = id.replace("root_", "")?.slice(0, -7);

    const cssClass = `${validationErrors.current && validationErrors.current[Id]?.__errors.length ? " Mui-error" : ""}`;

    const isSingleSelect = props.schema?.properties?.SingleSelect;
    const isQuestionLevel = title !== "" && uiSchema?.calculatePercentComplete;
    const fileUploaded = !!appData[Id]?.file;

    let fileName = "";
    if (!isSingleSelect && isQuestionLevel && !_.isEmpty(appData[Id])) {
      if (!!appData[Id].file) {
        // WHEN UPLOADING A FILE
        fileName = appData[Id]?.file?.slice(appData[Id].file?.lastIndexOf("/") + 1);
      } else {
        // RETURNED FROM DB
        fileName = appData[Id]?.slice(appData[Id]?.lastIndexOf("/") + 1);
      }
    }

    return (
      <header id={id}>
        <div style={isQuestionLevel ? Styles.QUESTIONNAIRE.question : Styles.QUESTIONNAIRE.subCategory}>
          <span aria-hidden="true" className={cssClass}>
            {title}
          </span>
          {required && (
            <span aria-hidden="true" className={cssClass}>
               *
            </span>
          )}
        </div>
        {!isSingleSelect && isQuestionLevel && (
          <div style={{ display: "flex" }}>
            <div style={Styles.QUESTIONNAIRE.uploadedFile}>Uploaded File:</div>
            <div style={Styles.QUESTIONNAIRE.fileName}>{!fileUploaded && fileName}</div>
          </div>
        )}
      </header>
    );
  }

  const widgets = {
    MultiSelect_OtherOption: MultiSelect_OtherOption,
  };

  useEffect(() => {
    // RERENDER!
  }, [appData]);

  useEffect(() => {
    if (isDataLoaded) {
      setNodesDone(nodesDone + 1);
      setPercentDone(Math.round(((nodesDone + 1) / nodesTotal) * 100));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  const handleSnackbarClose = (event) => {
    setIsSnackbarOpen(false);
  };

  const snackBarAction = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleToggleClick = (event) => {
    setIsToggleActive((current) => !current);
  };

  const showSavedMessage = () => {
    setTimeout(() => {
      setIsShown((current) => !current);
    }, 800);
    setTimeout(() => {
      setIsShown(false);
    }, 1200);
  };

  const pushData = (data) => {
    fetch(getFormUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ flowId: mainFlowIdRef.current, partnerData: data, version: versionRef.current }),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          console.log("Data:", data);
          if (data.hasChanged === true) {
            setIsSubmitActive(true);
          }
          if (data.error) {
            setSnackBarMessage(snackBarMessages.fileUploadFail);
          } else {
            setSnackBarMessage(snackBarMessages.fileUploadSuccess);
          }
          showSavedMessage();
        })
        .catch((error) => {
          setSnackBarMessage(snackBarMessages.fileUploadFail);
          console.error("Error:", error);
        })
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(_.debounce(pushData, 1500), []);

  const onChange = (event) => {
    let currId = null;

    if (Object.keys(event.errorSchema).length !== 0) {
      if (event.errorSchema.$schema === undefined) {
        currId = Object.keys(event.errorSchema)[0];
      } else {
        currId = Object.keys(event.errorSchema).find((key) => {
          if (typeof event.errorSchema[key] === "object") {
            return Object.values(event.errorSchema[key]).includes(undefined);
          } else {
            return event.errorSchema[key] === undefined;
          }
        });
      }

      if (_.has(event.formData[currId], "file")) {
        // FILE UPLOADS
        setSnackBarMessage(snackBarMessages.fileUploading);
        setIsSnackbarOpen(true);
        pushData({ [currId]: event.formData[currId].file });
      } else if (_.has(event.formData[currId], "SingleSelect")) {
        // SINGLE SELECT
        pushData({ [currId]: event.formData[currId].SingleSelect });
      } else if (Array.isArray(event.formData[currId])) {
        // MULTI SELECT
        pushData({ [currId]: event.formData[currId] });
      } else if (_.has(event.formData[currId], "value")) {
        // MULTI SELECT OTHER
        debouncedSave({ [currId]: { value: event.formData[currId].value, Other: event.formData[currId].Other } });
      } else {
        // FREEFORM
        debouncedSave({ [currId]: event.formData[currId] === undefined ? null : event.formData[currId] });
      }
    }
    setAppData(event.formData);
  };

  const customValidate = (formData, errors, uiSchema) => {
    incomplete.current = false;
    Object.keys(formSchema.properties).forEach((key) => {
      if (formSchema.required.includes(key)) {
        let isError = false;
        if (_.has(formData[key], "file") && formData[key].file === undefined) {
          // FILE UPLOADS
          isError = true;
        } else if (_.has(formData[key], "SingleSelect") && !formData[key].SingleSelect) {
          // SINGLE SELECT
          isError = true;
        } else if (Array.isArray(formData[key]) && !formData[key].length) {
          // MULTI SELECT
          isError = true;
        } else if (_.has(formData[key], "value") && !formData[key].value.length) {
          // MULTI SELECT OTHER
          isError = true;
        } else if (
          formData[key] === null ||
          formData[key] === "undefined" ||
          formData[key] === undefined ||
          formData[key] === ""
        ) {
          // FREEFORM
          isError = true;
        }

        if (isError) {
          errors[key].addError("This field is required");
          incomplete.current = true;
        }
      }
    });

    validationErrors.current = errors;
    return errors;
  };

  if (isDataLoaded) {
    return (
      <>
        <div className="formJSON col-2-3 flex-direction-column">
          {isToggleActive ? (
            ""
          ) : (
            <div>
              <div className="last-submitted">Last submitted on: {lastSubmitted}</div>
              <FormSplitter
                schemas={schemas}
                insightSchema={insightSchema}
                isDataLoaded={isDataLoaded}
                isToggleActive={isToggleActive}
                version={version}
                mainFlowId={mainFlowId}
              />
              <Form
                schema={formSchema}
                uiSchema={formSchemaUI}
                validator={validator}
                onChange={onChange}
                formData={appData}
                onSubmit={onSubmit}
                liveValidate={false}
                noHtml5Validate={true}
                readonly={false}
                widgets={widgets}
                templates={{ TitleFieldTemplate }}
                customValidate={customValidate}
                showErrorList={false}
                ref={ref}
              />
              <div className={"col-1-1 flex-center-all"}>
                <Snackbar
                  open={isSnackbarOpen}
                  onClose={handleSnackbarClose}
                  message={snackBarMessage}
                  action={snackBarAction}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  ContentProps={{
                    sx: {
                      background: "#359594",
                      padding: "12px 24px",
                    },
                  }}
                />
              </div>
            </div>
          )}
          {isShown && <AutoSave />}
          {isToggleActive && <FormTable appData={appData} insightSchema={insightSchema} isDataLoaded={isDataLoaded} />}
          <TableView handleToggleClick={handleToggleClick} isToggleActive={isToggleActive} />
        </div>
      </>
    );
  } else {
    return (
      <div className={"formJSON col-1-2 flex-center-all flex-direction-column"}>
        <h3 className={"center-text"}>Loading form interface</h3>
        <CircularProgress className={"padding-64"} />
      </div>
    );
  }
};

const FormJSONRef = forwardRef(FormJSON);
export default FormJSONRef;
