import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./styles/Loader.css";

const Header = () => {
  return (
    <div className="loader col-1-1 flex-center-all flex-direction-column">
      <div>
        <h3>Checking your credentials</h3>
      </div>
      <div>
        <CircularProgress />
      </div>
    </div>
  );
};

export default Header;
