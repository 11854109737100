import React from "react";
import "./styles/TaskStatus.css";

const TaskStatus = () => {
  return (
    <div className="taskStatus">
      <div className="steps col-1-1">
        <div className="step col-1-4 flex-direction-column step-done" id="el-send">
          <div className="step-circle"></div>
          <div className="step-title">Initialize</div>
        </div>
        <div className="step col-1-4 flex-direction-column step-active" id="el-upload">
          <div className="step-circle"></div>
          <div className="step-title">Submit</div>
        </div>
        <div className="step col-1-4 flex-direction-column step-pending" id="el-review">
          <div className="step-circle"></div>
          <div className="step-title">Review</div>
        </div>
        <div className="step col-1-4 flex-direction-column step-pending" id="el-approve">
          <div className="step-circle"></div>
          <div className="step-title">Approve</div>
        </div>
      </div>
    </div>
  );
};

export default TaskStatus;
