import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import "./styles/Hero.css";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "./styles";

const Hero = (props) => {
  const { title, version, bgColor, partner, mainFlowId, handleSubmit, incomplete, isSubmitActive, setIsSubmitActive } = props;

  const [submitText, setSubmitText] = useState("Submit Evaluation");
  const [para, setPara] = useState("Please fill out the form below and once complete submit your evaluation.");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const snackBarMessages = {
    submitting: "Submitting your inputs, hang tight...",
    submitted: "Successfully submitted!",
    submittedPara: "Thank you for submitting your evaluation!",
    submittedButton: "Submitted",
    failed: "Ooooops, something went wrong!",
  };

  const baseApi = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    let ss = urlParams.get("resubmit");
    if (ss === "true") {
      setSubmitText("Re-Submit Evaluation");
      setPara("Welcome back! Please make changes to the form below and re-submit your evaluation.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const handleSnackbarClose = (event) => {
    setIsSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function handleSubmitClick(e) {
    handleSubmit(e);
    if (!incomplete.current) {
      setPara("Thank you for submitting your retail media evaluation.");
      submitData();
    } else {
      setSnackMessage("Please fill out all the required questions.");
      setIsSnackbarOpen(true);
    }
  }

  function submitData() {
    setSnackMessage(snackBarMessages.submitting);
    setIsSnackbarOpen(true);
    fetch(`${baseApi}/flow/form/submit`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
      body: JSON.stringify({ flowId: mainFlowId, version }),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (data.error) {
            setSnackMessage(snackBarMessages.failed);
          } else {
            setSnackMessage(snackBarMessages.submitted);
            setSubmitText(snackBarMessages.submittedButton);
            setPara(snackBarMessages.submittedPara);
            setIsSubmitActive(false);
          }
        })
        .catch((error) => {
          setSnackMessage("Oooops, your submission failed!");
          console.error("Error:", error);
        })
    );
  }
  return (
    <>
      <div className={"col-1-1 flex-center-all"}>
        <Snackbar
          open={isSnackbarOpen}
          onClose={handleSnackbarClose}
          message={snackMessage}
          action={action}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          ContentProps={{
            sx: {
              background: "#359594",
              padding: "12px 24px",
            },
          }}
        />
      </div>

      <div className="hero col-1-1" style={{ backgroundColor: bgColor }}>
        <div className="overlay"></div>
        {partner ? (
          <div className="partnerLogo">
            <img
              src={"https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/" + partner + ".png"}
              alt="Partner Logo"
            />
          </div>
        ) : null}

        <div className="col-1-2 flex-align-center flex-direction-column padding-left64">
          <h1>{title}</h1>
          <p>{para}</p>
            <Button
              className={"submitButton"}
              variant="contained"
              size="large"
              disabled={!isSubmitActive}
              onClick={handleSubmitClick}
              sx={Styles.QUESTIONNAIRE.submitButton}
            >
              {submitText}
            </Button>
        </div>
        <div className="col-1-2 flex-align-center"></div>
      </div>
    </>
  );
};

export default Hero;
