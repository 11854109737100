import React from "react";
import logoGroupM from "../images/logo-groupm.png";
import badgeFusion from "../images/badge-fusion.png";
import "./styles/Header.css";

const Header = (props) => {
  const { title } = props;

  return (
    <div className="header">
      <div className="col-1-5 flex-direction-column flex-align-start">
        <img src={logoGroupM} alt="logo GroupM" id="logo-groupm" />
      </div>
      <div className="col-3-5">
        <h2>{title}</h2>
      </div>
      <div className="col-1-5 flex-direction-column flex-align-end">
        <img src={badgeFusion} alt="logo Fusion" id="badge-fusion" />
      </div>
    </div>
  );
};

export default Header;
